// 数数-埋点

import { THINKING_DATA_APP_ID, THINKING_DATA_SERVER_URL } from '@/config'
import thinkingdata from 'thinkingdata-browser'

type Properties = Record<string, any>

export const TA_EVENT_MAP = {
  enterPage: 'enter_page_web',
  completeTask: 'task_complete',
  voteSuccessGifr: 'vote_sucess_gifr'
}

const config = {
  showLog: false,
  appId: THINKING_DATA_APP_ID,
  serverUrl: THINKING_DATA_SERVER_URL,
  useAppTrack: true,
  autoTrack: {
    pageShow: true, //开启页面展示事件，事件名ta_page_show
    pageHide: true //开启页面隐藏事件，事件名ta_page_hide
  }
}

export function taInit() {
  thinkingdata.init(config)
}

export function taSetSuperProperties(superProperties: Properties) {
  thinkingdata.setSuperProperties(superProperties)
}

export function taTrack(event: string, props?: Properties) {
  thinkingdata.track(event, props)
}

export function taUserSet(props: Properties) {
  thinkingdata.userSet(props)
}

export function taGetDistinctId() {
  return thinkingdata.getDistinctId()
}

export function taEnterPageTrack(
  nextPage: string,
  entrance = '',
  subPage = ''
) {
  taTrack(TA_EVENT_MAP.enterPage, {
    page_web_id: nextPage,
    sub_page_web_id: subPage,
    page_web_entrance: entrance
  })
}
