import { IconType } from 'antd/lib/notification'
import { message, notification } from 'antd'

const notify = (msg: string, desc = '', type: IconType = 'error') => {
  notification.destroy()
  notification[type]({ message: msg, description: desc })
  setTimeout(() => {
    notification.destroy()
  }, 4500)
}

message.config({ top: 40 })
const notice = (msg: string, type: IconType = 'warning') => {
  // message.destroy()
  // message[type](msg)
  notify('', msg, type)
}

const errorNotice = (error: unknown) => {
  try {
    if (error instanceof Error) {
      throw error
    }
    throw new Error(
      typeof error === 'object' ? JSON.stringify(error) : String(error)
    )
  } catch (e: unknown) {
    if ((e as Error).message === '') {
      return
    }
    notify('Error', (e as Error).message)
  }
}

export { errorNotice, notify, notice }
