const IMG_BASE_URL = 'https://static.meet48.xyz'

const PAGE_SIZE = 1000

export const THINKING_DATA_SERVER_URL = 'https://shushu.meet48.xyz'

export const THINKING_DATA_APP_ID = process.env.isTestOrDev
  ? 'debug-appid'
  : '1accb4657cd04307838e4198367183fa'

// 更新的时候记得更新index.html里的key
export const GOOGLE_RECAPTCHA_KEY = '6LfONuopAAAAAGqH2Mhavt1x9umW-PPRX38YHeeu'

const WEB3_BLOCK_HASH_PATH = process.env.isTestOrDev
  ? 'https://testnet.opbnbscan.com/tx'
  : 'https://opbnbscan.com/tx'

export const BEST7_AUDIO_PREFIX = 'https://static.meet48.xyz/resource'
export const BEST7_ALBUM_COVER_PREFIX =
  'https://static.meet48.xyz/resource/img/cp'

export { IMG_BASE_URL, PAGE_SIZE, WEB3_BLOCK_HASH_PATH }
