import { errorNotice } from '@/common/tip'
import { MediaInfo, TaskApi } from '@/services/task'
import { create } from 'zustand'

type State = {
  mediaInfo: MediaInfo
  loading: boolean
}

type Actions = {
  updateMediaInfo: (info: MediaInfo) => void
  resetMediaInfo: () => void
  fetchMediaInfo: (accountId?: string) => void
  unbindByType: (type: 'Discord' | 'Twitter') => void
}

const INITIAL_MEDIA_INFO: MediaInfo = {
  discordName: null,
  twitterName: null,
  twitterToken: null,
  discordToken: null,
  userReceiveTaskStatus: true
}

const useMediaInfo = create<State & Actions>((set) => ({
  mediaInfo: INITIAL_MEDIA_INFO,
  loading: false,
  updateMediaInfo: (info) => set({ mediaInfo: info }),
  resetMediaInfo: () => set({ mediaInfo: INITIAL_MEDIA_INFO }),
  fetchMediaInfo: async (accountId?: string) => {
    if (!accountId) {
      return
    }
    set({ loading: true })
    const res = await TaskApi.getMediaInfo(accountId)
    set({ loading: false })
    if (res.data.code === 0) {
      set({ mediaInfo: res.data.data })
    } else {
      errorNotice(res.data.message)
    }
  },
  unbindByType: (type) => {
    const keyName = type === 'Discord' ? 'discordName' : 'twitterName'
    set((state) => {
      return {
        ...state,
        mediaInfo: {
          ...state.mediaInfo,
          [keyName]: null
        }
      }
    })
  }
}))

export { useMediaInfo }
