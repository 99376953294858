import axios from 'axios'
import ERROR_STATUS_MAP from './errorStatus'
import { useUserInfo } from 'store/useUserInfo'
import { useEnvInfo } from 'store/useEnvInfo'
import i18next from 'i18next'
import { getJsonData } from '@/utils/utils'
import { notify } from '@/common/tip'

// import * as process from 'process'

// 默认请求超时时间
const timeout = 60000

// 创建axios实例
export const service = axios.create({
  timeout,
  baseURL: '',
  //如需要携带cookie 该值需设为true
  withCredentials: true
})

const errorHandle = (error: any) => {
  // if (error.response?.status === 401) {
  //   notify('401')
  //   // todo, token失效时如何处理
  //   // handleLogout()
  //   return
  // }
  let message = `${error.response?.status ?? ''} ${error.code}`
  let description = `${i18next.t('http.serverError')} \n ${error.config?.url}`
  if (/timeout/i.test(error.message)) {
    message = error.code
    description = `${i18next.t('http.timeout')} \n ${error.config?.url}`
  }
  if (ERROR_STATUS_MAP.has(error.response?.status)) {
    const errorDesc = ERROR_STATUS_MAP.get(error.response.status)!
    message = `${error.response.status} ${errorDesc.title}`
    description = `${i18next.t(errorDesc.detail)} \n ${error.config.url}`
  }
  notify(message, description)
}

function getBaseURL() {
  if (process.env.NODE_ENV === 'development') {
    return ''
  }
  if (process.env.isPre) {
    return 'https://meetapi-v3.meet48.xyz/meet48-api'
  }
  return process.env.isTestOrDev
    ? 'https://betatest-meetapi-v1.meet48.xyz/meet48-api'
    : 'https://meetapi-v2.meet48.xyz/meet48-api'
}

//统一请求拦截 可配置自定义headers 例如 language、token等
service.interceptors.request.use((config) => {
  const token = useUserInfo.getState().userInfo?.token
  if (token) {
    config.headers.set('token', token)
  }

  config.url = getBaseURL() + config.url

  config.headers.set('Content-Type', 'application/json;charset=UTF-8')
  config.headers.set('X-VersionCode', '1.0.0')

  if (useEnvInfo.getState().envInfo.deviceType) {
    config.headers.set(
      'X-Custom-Device-Type',
      useEnvInfo.getState().envInfo.deviceType
    )
    // 这个接口需要人机验证，而人机验证的数据分析基于web界面操作的，所以需要type为h5
    if (config.url?.includes('/sendEmail')) {
      config.headers.set('X-Custom-Device-Type', 'H5')
    }
  }

  config.headers.set('Accept-Language', i18next.language)
  return config
})

service.interceptors.response.use(
  (res) => {
    return res
  },
  (error) => {
    errorHandle(error)
    return { data: { message: '' } } as any
  }
)
