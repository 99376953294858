import React, { createContext, useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import type { ModalContentProps, ModalProps } from '@/common/types'

import { useProhibitScroll } from '@/hooks/useProhibitScroll'

interface ModalsContext {
  content?: React.ReactNode
  isOpen?: boolean
  onPresent: (
    content: React.ReactNode,
    contentProps?: ModalContentProps,
    modalProps?: ModalProps
  ) => void
  onDismiss: () => void
}

export const Context = createContext<ModalsContext>({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onPresent: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onDismiss: () => {}
})

const Modals = ({ children }: React.PropsWithChildren) => {
  const [isOpen, setIsOpen] = useState(false)
  const [content, setContent] = useState<React.ReactNode>()
  const [contentProps, setContentProps] = useState<ModalContentProps>()
  const [props, setProps] = useState<ModalProps>()
  const { maskClosable = true } = props ?? ({} as ModalProps)

  // 当为true时，点击关闭按钮也无法关闭
  const [closable, setClosable] = useState(true)

  function handleClosable() {
    setClosable(true)
  }

  function handleUnClosable() {
    setClosable(false)
  }

  useProhibitScroll(isOpen) //禁止滚动

  const handlePresent = useCallback(
    (
      modalContent: React.ReactNode,
      contentProps?: ModalContentProps,
      props?: ModalProps
    ) => {
      setContent(modalContent)
      setIsOpen(true)
      setContentProps(contentProps)
      setProps(props) // 同时具备设置和重置为undefined的作用
    },
    []
  )

  const handleDismiss = useCallback(() => {
    if (closable) {
      setContent(undefined)
      setIsOpen(false)
    }
  }, [closable])

  const handleDismissByDisclaim = useCallback(() => {
    if (maskClosable) {
      setContent(undefined)
      setIsOpen(false)
    }
  }, [maskClosable])

  return (
    <Context.Provider
      value={{
        content,
        isOpen,
        onPresent: handlePresent,
        onDismiss: handleDismiss
      }}
    >
      {children}
      {isOpen && (
        <StyledModalWrapper>
          <StyledModalBackdrop onClick={handleDismissByDisclaim} />
          {React.isValidElement(content) &&
            React.cloneElement(content, {
              onDismiss: handleDismiss,
              handleUnClosable,
              handleClosable,
              ...contentProps
            } as any)}
        </StyledModalWrapper>
      )}
    </Context.Provider>
  )
}

const StyledModalWrapper = styled.div`
  z-index: 100;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`

const StyledModalBackdrop = styled.div`
  backdrop-filter: blur(5px);
  background-color: #0000009c;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`

export default Modals
