import { JSEncrypt } from 'jsencrypt'
import { Lang, LangData, Obj } from '@/common/types'
import dayjs from 'dayjs'
import i18next from 'i18next'
import { IMG_BASE_URL } from '@/config'

//验证邮箱
export const checkEmail = (value: string) => {
  return /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/.test(
    value
  )
}

//公钥加密
export const rsaEncrypt = (text: string) => {
  const encrypt = new JSEncrypt()
  const PublicKey = window.sessionStorage.getItem('PublicKey') || ''
  encrypt.setPublicKey(PublicKey)
  return encrypt.encrypt(text)
}

export const formatAddress = (address: string, start = 6, end = -6): string => {
  return address ? address.slice(0, start) + '...' + address.slice(end) : ''
}

//请求参数添加 'chain'：'BNB'
export const paramAddChain = (params: Obj) => {
  params['chain'] = 'BNB'
  return params
}

//除4取余
export const getRemainder = (num: number) => {
  const count = (num + 1) % 4
  return count === 0 ? 4 : count
}

//以4循环取色
export const handleTaskColor = (key: string, alpha: number) => {
  switch (key) {
    case 'webTasks':
      return `rgba(222, 23, 213,${alpha})`
    case 'promotionalTasks':
      return `rgba(174, 26, 235,${alpha})`
    case 'visitingTasks':
      return `rgba(244, 156, 152,${alpha})`
    case 'bindTasks':
      return `rgba(215, 93, 74,${alpha})`
    case 'twitterTasks':
      return `rgba(253, 215, 51,${alpha})`
    default:
      return `rgba(222, 23, 213,${alpha})`
  }
}

export const getSearchParams = () => {
  const hash = window.location.hash
  const search = window.location.search || hash.slice(hash.indexOf('?'))
  const searchParamsStr = search.slice(1)
  return Object.fromEntries(new URLSearchParams(searchParamsStr))
}

export const getJsonData = (jsonStr: unknown) => {
  try {
    if (typeof jsonStr === 'object') {
      return jsonStr
    }
    if (typeof jsonStr === 'string' && jsonStr.length) {
      return JSON.parse(jsonStr)
    }
  } catch (e) {
    console.log('getJsonData error', e)
  }
  return null
}

export const getHourTime = (ms: number) => {
  const seconds = Math.floor(ms / 1000)
  const hour = Math.floor(seconds / 3600)
  const minute = Math.floor((seconds - hour * 3600) / 60)
  const second = seconds % 60
  return {
    hour: `${hour}`.padStart(2, '0'),
    minute: `${minute}`.padStart(2, '0'),
    second: `${second}`.padStart(2, '0')
  }
}

export const getDetailTime = (timestamp: number, hasZero = true) => {
  const d = new Date(timestamp)
  const year = d.getFullYear()
  const month = d.getMonth() + 1
  const date = d.getDate()
  const hour = d.getHours()
  const minute = d.getMinutes()
  const second = d.getSeconds()
  const millisecond = d.getMilliseconds()
  return {
    year: `${year}`,
    month: hasZero ? `${month}`.padStart(2, '0') : `${month}`,
    date: hasZero ? `${date}`.padStart(2, '0') : `${date}`,
    hour: hasZero ? `${hour}`.padStart(2, '0') : `${hour}`,
    minute: hasZero ? `${minute}`.padStart(2, '0') : `${minute}`,
    second: hasZero ? `${second}`.padStart(2, '0') : `${second}`,
    millisecond: hasZero ? `${millisecond}`.padStart(3, '0') : `${millisecond}`
  }
}

export const debouncePromise = <T>(
  fn: (...rest: any[]) => Promise<T>,
  time = 300
) => {
  let timer: NodeJS.Timer
  let promise: Promise<T> | undefined
  let res: ((value: T | PromiseLike<T>) => void) | undefined
  const timeoutHandle = (...args: any[]) => {
    res!(fn(...args))
    promise = undefined
    res = undefined
  }
  return (...args: any[]) => {
    if (!promise) {
      promise = new Promise(function (resolve) {
        res = resolve
        timer = setTimeout(() => timeoutHandle(...args), time)
      })
    } else {
      clearTimeout(timer)
      timer = setTimeout(() => timeoutHandle(...args), time)
    }
    return promise
  }
}

export const isObj = (obj: any) => {
  return Object.prototype.toString.call(obj) === '[object Object]'
}

export const parseCookie = (cookie = ''): Obj => {
  const items = cookie.split(';')
  return items.reduce((prev, item) => {
    const [key, value] = item.split('=')
    return { ...prev, [key.trim()]: value }
  }, {})
}

type FormatConfig = {
  format?: string
  offset?: number
}

export function formatTime(timestamp: number, config: FormatConfig = {}) {
  const { format = 'YYYY-MM-DD HH:mm:ss', offset = 0 } = config
  return dayjs(timestamp).add(offset, 'hour').format(format)
}

enum Month {
  January = 0,
  February = 1,
  March = 2,
  April = 3,
  May = 4,
  June = 5,
  July = 6,
  August = 7,
  September = 8,
  October = 9,
  November = 10,
  December = 11
}

function getDay(day: number) {
  if (day === 1) {
    return '1st'
  }
  if (day === 2) {
    return '2nd'
  }
  if (day === 3) {
    return '3rd'
  }
  return `${day}th`
}

export function getEnTime(timestamp: number) {
  const date = new Date(timestamp)
  const year = date.getFullYear()
  const month = Month[date.getMonth()]
  const day = getDay(date.getDate())
  const hour = `${date.getHours()}`.padStart(2, '0')
  const minute = `${date.getMinutes()}`.padStart(2, '0')
  return `${month} ${day} ${hour}:${minute}`
}

export function isBrowser() {
  const cookies = parseCookie(document.cookie)
  const envInfoCookie = cookies['getEnvInfo']
  const agent = navigator.userAgent
  const isAndroid =
    (/Android/i.test(agent) || /Adr/i.test(agent)) && !!envInfoCookie
  const isIOSApp = /sdk-ios/i.test(agent)
  return !(isAndroid || isIOSApp)
}

// ios app -> /td-sdk-ios
// android -> Mozilla/5.0 (Linux;Android 12 ...)
// windows -> Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/120.0.0.0 Safari/537.36
// safari  -> Mozilla/5.0 (iPhone; CPU iPhone OS) ...

export function getLangData(data?: LangData) {
  if (!data) {
    return ''
  }
  return data[i18next.language as Lang]
}

export function getMediaSrc(src?: string) {
  if (!src) {
    return undefined
  }
  if (src.startsWith('http') || src.startsWith('//')) {
    return src
  }
  return `${IMG_BASE_URL}/${src}`
}

export function formatNumberWithCommas(number: number) {
  const numberParts = number.toString().split('.')
  numberParts[0] = numberParts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  return numberParts.join('.')
}

export function paddingTime(time?: number | null | string, replacer = '') {
  if (typeof time === 'string') {
    return time
  }
  if (typeof time === 'number') {
    return `${time}`.padStart(2, '0')
  }
  return replacer
}

// 将秒转化为 00:00:00 这种格式
export function convertSecondToMinute(duration: number) {
  const minute = Math.floor(duration / 60)
  const second = Math.floor(duration % 60)
  return `${paddingTime(minute)}:${paddingTime(second)}`
}

export function getVideoDurationByURL(url: string): Promise<number> {
  if (!url) {
    console.log('url not exist')
    return Promise.resolve(0)
  }
  return new Promise((resolve, reject) => {
    const video = document.createElement('video')
    video.src = getMediaSrc(url)!
    video.addEventListener('loadedmetadata', () => {
      resolve(video.duration)
      video.remove()
    })
    video.addEventListener('error', () => {
      resolve(0)
      video.remove()
    })
    document.body.appendChild(video)
  })
}
