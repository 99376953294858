import { useEffect, useRef } from 'react'

export function useProhibitScroll(open: boolean) {
  const refTop = useRef(0)

  useEffect(() => {
    if (open) {
      refTop.current = window.scrollY
      document.body.style.cssText = `position:fixed;top:${-refTop.current}px`
    } else {
      document.body.style.cssText = ''
      window.scrollTo(0, refTop.current) // 回到原先的top
    }
  }, [open])
}
