import { errorNotice } from '@/common/tip'
import { VOTE_DATA } from '@/constants/vote'
import { ActivityApi } from '@/services/activityAPI'
import { UserAsset, WalletApi, AssetName } from '@/services/wallet'
import { create } from 'zustand'

type State = { assets: UserAsset[]; couponNum: number }

type Actions = {
  getAssets: (accountId: string) => Promise<void>
  getAssetByName: (assetName: AssetName) => number
  clearAssets: () => void
  getCouponNum: () => void
}

async function getCouponNum() {
  const res = await ActivityApi.getUserVoteNum(VOTE_DATA.activityId)
  if (res.data.code === 0) {
    return typeof res.data.data === 'number' ? res.data.data : 0
  }
  errorNotice(res.data.message)
  return 0
}

const useAssets = create<State & Actions>((set, get) => ({
  couponNum: 0,
  assets: [],
  async getAssets(accountId) {
    if (!accountId) {
      return
    }
    const res = await WalletApi.getUserAsset(accountId)
    if (res.data.code === 0) {
      set({ assets: res.data.data })
    } else {
      errorNotice(res.data.message)
    }
    const num = await getCouponNum()
    set({ couponNum: num })
  },
  getAssetByName(assetName: AssetName) {
    const { assets } = get()
    const target = assets.find((asset) => asset.assetName === assetName)
    return target?.available ?? 0
  },
  clearAssets() {
    set({ assets: [], couponNum: 0 })
  },
  async getCouponNum() {
    const num = await getCouponNum()
    set({ couponNum: num })
  }
}))

export { useAssets }
