import React from 'react'
import { useModal as useStoreModal, getRoot } from '@/store/useModal'
import { createPortal } from 'react-dom'
import Modal from './Modal'

const GlobalBroadcast: React.FC = () => {
  const { modals } = useStoreModal()

  return (
    <>
      {modals.map((modal) => {
        return createPortal(
          <Modal id={modal.id} maskClosable={modal.config?.maskClosable}>
            {modal.modal}
          </Modal>,
          getRoot()
        )
      })}
    </>
  )
}

export default React.memo(GlobalBroadcast)
