import { getLocalItem, setLocalItem } from '@/common/storage'
import { EnvInfo } from '@/common/types'
import { getJsonData, isObj, parseCookie } from '@/utils/utils'
import i18next from 'i18next'
import { create } from 'zustand'

type State = { envInfo: EnvInfo }

type Actions = {
  save: (info: EnvInfo) => void
  update: (info: Partial<EnvInfo>) => void
  clear: () => void
}

function getDeviceType() {
  const isAndroid =
    /Android/i.test(navigator.userAgent) || /Adr/i.test(navigator.userAgent)
  const type = isAndroid ? 'ANDROID' : 'IOS'
  if (type) {
    setLocalItem('deviceType', type)
  }
  return type
}

const DEFAULT_INFO: EnvInfo = {
  statusBarHeight: 0,
  languageType: 'en_US',
  theme: 'light',
  deviceType: (getLocalItem('deviceType') ||
    getDeviceType()) as EnvInfo['deviceType']
}

function changeTheme(theme: string) {
  document.documentElement.setAttribute('data-theme', theme)
}

function init() {
  const cookies = parseCookie(document.cookie)
  const data = getJsonData(cookies['getEnvInfo'])
  document.documentElement.setAttribute(
    'data-theme',
    data?.theme ?? DEFAULT_INFO.theme
  )
}

init()

const fn = window.requestIdleCallback || window.requestAnimationFrame

function loop(lang: string) {
  if (i18next.isInitialized) {
    i18next.changeLanguage(lang)
  } else {
    fn(() => loop(lang))
  }
}

function changeLang(lang: string) {
  // document.documentElement.lang = lang
  if (i18next.isInitialized) {
    i18next.changeLanguage(lang)
  } else {
    if (fn) {
      fn(() => loop(lang))
    } else {
      setTimeout(() => i18next.changeLanguage(lang), 300)
    }
  }
}

const useEnvInfo = create<State & Actions>((set) => ({
  envInfo: DEFAULT_INFO,
  save: async (info) => {
    if (isObj(info) && Object.keys(info).length > 0) {
      changeLang(info.languageType || DEFAULT_INFO.languageType)
      changeTheme(info.theme || DEFAULT_INFO.theme)
      set({ envInfo: info })
    }
  },
  update: (info) => {
    if (info.languageType) {
      changeLang(info.languageType)
    }
    if (info.theme) {
      changeTheme(info.theme)
    }
    set((state) => {
      return {
        envInfo: {
          ...state.envInfo!,
          ...info
        }
      }
    })
  },
  clear: () => {
    set({ envInfo: DEFAULT_INFO })
  }
}))

export { useEnvInfo }
