import React from 'react'
import styles from './style.module.less'

const Footer = () => {
  return (
    <div className={styles.footer}>
      <div>git latest message: &quot;{process.env.gitMessage}&quot;</div>
      <div>git latest short commit: {process.env.shortCommit}</div>
    </div>
  )
}

export default Footer
