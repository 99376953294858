import { create } from 'zustand'
import { UserInfo } from '@/services/wallet'
import { setSessionItem } from '@/common/storage'
import { isObj } from '@/utils/utils'

type State = { userInfo?: UserInfo }

type Actions = {
  save: (info: UserInfo) => void
  update: (info: Partial<UserInfo>) => void
  clear: () => void
}

const useUserInfo = create<State & Actions>((set) => ({
  userInfo: undefined,
  save: async (info) => {
    if (isObj(info) && Object.keys(info).length > 0) {
      setSessionItem('token', info.token || '')
      set({ userInfo: info })
    }
  },
  update: (info) => {
    if (info.token) {
      setSessionItem('token', info.token)
    }
    set((state) => {
      return {
        userInfo: {
          ...state.userInfo!,
          ...info
        }
      }
    })
  },
  clear: () => {
    set({ userInfo: undefined })
  }
}))

export { useUserInfo }
