import { Lang, Theme } from '@/common/types'
import { UserInfo } from '@/services/wallet'
import { useEnvInfo } from '@/store/useEnvInfo'
import { useUserInfo } from '@/store/useUserInfo'
import { getJsonData } from '@/utils/utils'
import { useEffect } from 'react'
import { hideHeaderBar, setupBridge } from '@/common/bridge'
import { useAssets } from '@/store/useAssets'
import { useMediaInfo } from '@/store/useMediaInfo'
import { useTasks } from '@/store/useTasks'

const DEFAULT_USER_INFO: UserInfo = {
  accountId: 'wallet-2023061914290206014637',
  token:
    'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhY2NvdW50SWQiOiJ3YWxsZXQtMjAyMzA2MTkxNDI5MDIwNjAxNDYzNyIsImlkIjoxODcsInN0YXRlIjoiMSIsInVzZXJOYW1lIjoiaGlyYXJhIiwiZXhwIjoxNzAwNzMyMzY3fQ.PQTOlgpZ83GWfFJp75dzC5GUoNy-INBIgl5oe5CxoUM',
  inviteCode: 'Z41LT8F9',
  userName: 'hirara',
  userId: 187,
  userType: '1',
  type: '1',
  id: 187,
  avatar: ''
}

const NON_HEADER_PATHS = [
  '/activity',
  '/rule',
  '/search-idol',
  '/support-record',
  '/vote',
  '/ranking-intro',
  '/live',
  '/best7',
  '/best7-vote-records'
]

function checkNeedHidden() {
  return NON_HEADER_PATHS.some((path) => window.location.hash.includes(path))
}

function init() {
  const isNeedHidden = checkNeedHidden()
  if (isNeedHidden) {
    hideHeaderBar()
  }
}

const useBridge = () => {
  const { save: saveEnvInfo, update } = useEnvInfo()
  const { save: saveUserInfo } = useUserInfo()
  const { getAssets } = useAssets()
  const { fetchMediaInfo } = useMediaInfo()
  const { getList } = useTasks()

  useEffect(() => {
    setupBridge(function (bridge) {
      try {
        bridge?.init?.()
        init()
        // 原生事件监听注册
        bridge?.registerHandler('changeTheme', function (theme: Theme) {
          update({ theme })
        })
        bridge?.registerHandler(
          'changeLanguage',
          function (languageType: Lang) {
            update({ languageType })
          }
        )
        bridge?.registerHandler('refreshData', function () {
          getAssets(useUserInfo.getState().userInfo?.accountId ?? '')
          fetchMediaInfo(useUserInfo.getState().userInfo?.accountId ?? '')
          getList(useUserInfo.getState().userInfo?.accountId)
        })
      } catch (error: any) {
        console.log('error:', error)
      }
    })
  }, [])

  const getEnvInfo = (cb: () => void) => {
    setupBridge(function (bridge) {
      bridge?.callHandler('getEnvInfo', null, function (response: any) {
        saveEnvInfo(getJsonData(response))
        cb()
      })
    })
  }

  const getUserInfo = (cb: () => void) => {
    setupBridge(function (bridge) {
      bridge?.callHandler('getUserInfo', null, function (response: any) {
        saveUserInfo(getJsonData(response))
        cb()
        // saveUserInfo(DEFAULT_USER_INFO)
      })
    })
  }

  return { getUserInfo, getEnvInfo }
}

export default useBridge
