import { TaskApi, TaskInfo } from '@/services/task'
import { create } from 'zustand'
import {
  ALL_TASKS,
  BIND_TASKS,
  DISCORD_TASKS,
  LUCKY_TASKS,
  PROMOTIONAL_TASKS,
  SUPPORT_TASKS,
  TWITTER_TASKS,
  VISITING_TASKS
} from '@/constants/task'

const ALL_TAB = { title: 'task.allTask', key: ALL_TASKS }

const TABS = [
  { title: 'task.visitingTasks', key: VISITING_TASKS },
  { title: 'task.luckyTask', key: LUCKY_TASKS },
  { title: 'task.callTask', key: SUPPORT_TASKS },
  { title: 'task.promotionalTask', key: PROMOTIONAL_TASKS },
  { title: 'task.bindTask', key: BIND_TASKS },
  { title: 'task.twitterTask', key: TWITTER_TASKS },
  { title: 'task.discordTask', key: DISCORD_TASKS }
]

type State = {
  taskList?: Record<string, TaskInfo[]>
  tabs: typeof TABS
}

type Actions = {
  getList: (accountId?: string) => void
}

const useTasks = create<State & Actions>((set) => ({
  taskList: undefined,
  tabs: [],
  getList: async (accountId?: string) => {
    const res = await TaskApi.getTaskList({ accountId })
    if (res.data.code === 0) {
      const existTaskKinds = Object.keys(res.data.data)
      const activeTabs = TABS.filter((tab) => existTaskKinds.includes(tab.key))
      set({
        taskList: res.data.data,
        tabs: [ALL_TAB, ...activeTabs]
      })
    }
  }
}))

export { useTasks }
